import React from "react";
import CbaLogo from "assets/img/brand/cba/justicia-logo.png";
import CbaLogoLight from "assets/img/brand/cba/justicia-logo-light.png";
import CbaFav from "assets/img/brand/cba/favicon-cordoba.png";
import DefaultCba from "assets/img/brand/cba/defaultCordoba.png";

import SLLogo from "assets/img/brand/sl/justicia-logo-san-luis.png";
import SLLogoLight from "assets/img/brand/sl/logo-san-luis-light.png";
import SLFav from "assets/img/brand/sl/favicon-san-luis.png";
import DefaultSL from "assets/img/brand/sl/defaultSan Luis.png";

import SELogo from "assets/img/brand/subastas_electronicas/se-logo.png";
import SELogoLight from "assets/img/brand/subastas_electronicas/se-logo-light.png";
import SEFav from "assets/img/brand/subastas_electronicas/se-iso-bordered-inverted.png";
import DefaultSE from "assets/img/brand/subastas_electronicas/default.png";

import ERLogo from "assets/img/brand/er/logo_er.png";
import ERLogoLight from "assets/img/brand/er/logo_er_inverted.png";
import ERFav from "assets/img/brand/er/er_iso.png";
import DefaultER from "assets/img/brand/er/defaultEntreRios.png";

import LPLogo from "assets/img/brand/lp/justicia-logo-lp.png";
import LPLogoLight from "assets/img/brand/lp/justicia-logo-lp-light.png";
import LPFav from "assets/img/brand/lp/lp_iso.png";
import DefaultLP from "assets/img/brand/lp/defaultLaPampa.png";

import NSSALogo from "assets/img/brand/nssa/NSSALogo.png";
import NSSALogoLight from "assets/img/brand/nssa/NSSALogoLight.png";
import NSSAFav from "assets/img/brand/nssa/nssaFavicon.png";
import DefaultNSSA from "assets/img/brand/nssa/nssaDefault.png";

import AduanaLogo from "assets/img/brand/nssa/aduana/aduanaLogo.png";
import AduanaLogoLight from "assets/img/brand/nssa/aduana/aduanaLogoLight.png";
import AduanaFav from "assets/img/brand/nssa/aduana/aduanaFavicon.png";
import DefaultAduana from "assets/img/brand/nssa/aduana/aduanaDefault.png";

import TestLogo from "assets/img/brand/test/test.png";
import TestLogoLight from "assets/img/brand/test/test-light.png";
import TestFav from "assets/img/brand/test/test-iso.png";
import DefaultTest from "assets/img/brand/test/defaultTest.png";

import JujuyLogo from "assets/img/brand/jujuy/justicia-logo-jujuy.png";
import JujuyLogoLight from "assets/img/brand/jujuy/justicia-logo-jujuy-light.png";
import JujuyFav from "assets/img/brand/jujuy/jujuy_iso.png";
import DefaultJujuy from "assets/img/brand/jujuy/defaultJujuy.png";

import SaltaLogo from "assets/img/brand/salta/justicia-logo-salta.png";
import SaltaLogoLight from "assets/img/brand/salta/justicia-logo-salta-light.png";
import SaltaFav from "assets/img/brand/salta/salta_iso.png";
import DefaultSalta from "assets/img/brand/salta/defaultSalta.png";

import ChacoLogo from "assets/img/brand/chaco/justicia-logo-chaco.png";
import ChacoLogoLight from "assets/img/brand/chaco/justicia-logo-chaco-light.png";
import ChacoFav from "assets/img/brand/chaco/chaco_iso.png";
import DefaultChaco from "assets/img/brand/chaco/defaultChaco.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";



export const site = "test2";
// export const site = 'jujuy';

export const sources = {
  //  Esta opcion para que se vea general (para futuros clientes de Subastas)
  //    test2:{
  //        url: document.location.origin,
  //        logo: TestLogo,
  //        logolight: TestLogoLight,
  //        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
  //        name: 'Subastas test',
  //        navbar_class: 'navbar-test',
  //        headroom_class: 'headroom-test',
  //        favicon: TestFav,
  //        theme_color: '#0075A8',
  //        theme_name: 'test',
  //        defaultImg: DefaultTest,
  //        recaptcha_key: '6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd',
  //        gtm_ID: 'GTM-P8LTPVC',
  //        title: 'Subastas Electrónicas',
  //        contact_data: {
  //            title: 'Test',
  //            lines: [
  //                '',
  //            ]
  //        },
  //        support_email: 'soporte@aduana.com.ar'
  //    },
  //  Esta opcion para que se vea como Privadas
    // test2:{
    // privadas:{
    //     url: document.location.origin,
    //     logo: SELogo,
    //     logolight: SELogoLight,
    //     ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
    //     name: 'Sitio de pruebas esubasta',
    //     navbar_class: 'navbar-transparent',
    //     headroom_class: 'headroom',
    //     favicon: SEFav,
    //     theme_color: '#f58723',
    //     theme_name: 'subastas',
    //     defaultImg: DefaultSE,
    //     interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

    //     title: 'Subastas Electrónicas Test',
    //     recaptcha_key: '6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd',
    //     gtm_ID: 'GTM-P8LTPVC',
    //     support_email: 'publicas@esubasta.ar',
    //     demo: true,
    // },
  //  Esta opcion para que se vea como Cordoba
    // test2:{
    //     url: document.location.origin,
    //     logo: CbaLogo,
    //     logolight: CbaLogoLight,
    //     ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
    //     name: 'Sitio de pruebas esubasta',
    //     navbar_class: 'navbar-cba',
    //     headroom_class: 'headroom-cba',
    //     favicon: CbaFav,
    //     theme_color: '#00857D',
    //     theme_name: 'cordoba',
    //     defaultImg: DefaultCba,
    //     interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

    //     title: 'Subastas Electrónicas Test',
    //     recaptcha_key: '6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd',
    //     gtm_ID: 'GTM-P8LTPVC',
    //     contact_data: {
    //         title: 'Oficina de subastas',
    //         lines: [
    //             'Arturo M. Bas 158, X5000KLD Córdoba',
    //             'Teléfono: +54 (351) 4481000-4481600 int. 37301',
    //             'Email: subastas@justiciacordoba.gob.ar',
    //         ]
    //     },
    //     support_email: 'soporte@esubasta.ar',
    //     demo: true,
    // },
  //  Esta opcion para que se vea como Entre Rios
  // test2: {
  //   url: document.location.origin,
  //   logo: ERLogo,
  //   logolight: ERLogoLight,
  //   ws_path: `${document.location.origin}/wss`
  //     .replace("http", "ws")
  //     .replace("https", "wss"),
  //   name: "Sitio de pruebas esubasta",
  //   navbar_class: "navbar-er",
  //   headroom_class: "headroom-er",
  //   favicon: ERFav,
  //   theme_color: "#676978",
  //   theme_name: "entrerios",
  //   defaultImg: DefaultER,
  //   interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

  //   title: "Subastas Electrónicas Test",
  //   recaptcha_key: "6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd",
  //   gtm_ID: 'GTM-P8LTPVC',
  //   contact_data: {
  //     title: "Oficina de subastas PJ de Entre Ríos",
  //     lines: [
  //       <p>
  //         <b>Laprida 255 - 2do piso</b>
  //       </p>,
  //       <p>
  //         <b>Palacio de tribunales de Paraná</b>
  //       </p>,
  //       <p>
  //         <b>Teléfonos:</b>
  //       </p>,
  //       <p>&emsp; 0343 4206140 (directo)</p>,
  //       <p>&emsp; 0343 4206100 (interno 248)</p>,
  //       <p>
  //         <b>email:</b> subastaselectronicas@jusentrerios.gov.ar
  //       </p>,
  //     ],
  //   },
  //   support_email: "soporte.er@esubasta.ar",
  // },
  //  Esta opcion para que se vea como La Pampa
  // test2: {
  //   url: document.location.origin,
  //   logo: LPLogo,
  //   logolight: LPLogoLight,
  //   ws_path: `${document.location.origin}/wss`
  //     .replace("http", "ws")
  //     .replace("https", "wss"),
  //   name: "Sitio de pruebas esubasta",
  //   navbar_class: "navbar-lp",
  //   headroom_class: "headroom-lp",
  //   favicon: LPFav,
  //   theme_color: "#5b3434",
  //   theme_name: "lapampa",
  //   defaultImg: DefaultLP,
  //   interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

  //   title: "Subastas Electrónicas Test",
  //   recaptcha_key: "6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd",
  //   gtm_ID: 'GTM-P8LTPVC',
  //   contact_data: {
  //     title: 'Secretaría de subastas PJ de La Pampa',
  //     lines: [
  //             <p><b>Avda Uruguay n° 1097 - Tercer piso</b></p>,
  //             <p><b>Centro Judicial Santa Rosa</b></p>,
  //             <p><b>Teléfono:</b></p>,
  //             <p>&emsp; 02954 - 452099 (7AM a 12:30PM)</p>,
  //             <p><b>email:</b></p>,
  //             <p>&emsp; servijuris@juslapampa.gob.ar</p>,
  //             <br />,
  //             <h5>Secretaría de sistemas y organización:</h5>,
  //             <p><b>Teléfono:</b></p>,
  //             <p>&emsp; 02954 - 451866 (Mesa de ayudas)</p>,
  //     ]
  //   },
  //   support_email: "soportelp@esubasta.ar",
  //   demo: true,
  // },
  //  Esta opcion para que se vea como Jujuy
  // test2: {
  //   url: document.location.origin,
  //   logo: JujuyLogo,
  //   logolight: JujuyLogoLight,
  //   ws_path: `${document.location.origin}/wss`
  //     .replace("http", "ws")
  //     .replace("https", "wss"),
  //   name: "Sitio de pruebas jujuy",
  //   navbar_class: "navbar-jujuy",
  //   headroom_class: "headroom-jujuy",
  //   favicon: JujuyFav,
  //   theme_color: '#516391',
  //   theme_name: "jujuy",
  //   defaultImg: DefaultJujuy,
  //   interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

  //   title: "Subastas Electrónicas Test",
  //   recaptcha_key: "6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd",
  //   gtm_ID: "GTM-P8LTPVC",
  //   tagManagerArgs: {
  //     gtmId: 'GTM-P8LTPVC',
  //     auth: 'OQo9235SOV2HENch9BNUMQ',
  //     preview: 'env-39',
  //   },
  //   demo: true,
  //   contact_data: {
  //     title: 'Ministerio de la acusación | Jujuy',
  //     lines: [
  //       <p>
  //         <b>Dirección</b>
  //       </p>,
  //       <p>
  //         <b>Provincia</b>
  //       </p>,
  //       <p>
  //         <b>Teléfonos:</b>
  //       </p>,
  //       <p>&emsp; completar (directo)</p>,
  //       <p>&emsp; completar (interno xxx)</p>,
  //       <p>
  //         <b>email:</b> completar
  //       </p>,
  //     ],
  //   },
  //   support_email: "soportempa@esubasta.ar",
  // },
  //  Esta opcion para que se vea como Salta
  test2: {
    url: document.location.origin,
    logo: SaltaLogo,
    logolight: SaltaLogoLight,
    ws_path: `${document.location.origin}/wss`
      .replace("http", "ws")
      .replace("https", "wss"),
    name: "Sitio de pruebas salta",
    navbar_class: "navbar-salta",
    headroom_class: "headroom-salta",
    favicon: SaltaFav,
    theme_color: '#ae553b',
    theme_name: "salta",
    defaultImg: DefaultSalta,
    interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],
    title: "Subastas Electrónicas Test",
    recaptcha_key: "6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd",
    gtm_ID: "GTM-P8LTPVC",
    tagManagerArgs: {
      gtmId: 'GTM-P8LTPVC',
      auth: 'OQo9235SOV2HENch9BNUMQ',
      preview: 'env-39',
    },
    demo: true,
    contact_data: {
      title: 'Secretaría de subastas PJ | Salta',
      lines: [
        <p>
          <b>Dirección</b>
        </p>,
        <p>
          <b>Provincia</b>
        </p>,
        <p>
          <b>Teléfonos:</b>
        </p>,
        <p>&emsp; completar (directo)</p>,
        <p>&emsp; completar (interno xxx)</p>,
        <p>
          <b>email:</b> completar
        </p>,
      ],
    },
    support_email: "soportesalta@esubasta.ar",
  },
  // Esta opción para que se vea como Chaco
  // test2: {
  //   url: document.location.origin,
  //   logo: ChacoLogo,
  //   logolight: ChacoLogoLight,
  //   ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
  //   name: 'Subastas Judiciales Chaco',
  //   navbar_class: 'navbar-chaco',
  //   headroom_class: 'headroom-chaco',
  //   favicon: ChacoFav,
  //   theme_color: '#007f01',
  //   theme_name: 'chaco',
  //   defaultImg: DefaultChaco,
  //   interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

  //   title: 'Subastas Judiciales',
  //   recaptcha_key: '6LfjmtgdAAAAAGv3C9DQSL143rEisL964E7a6jCd',
  //   gtm_ID: 'GTM-P8LTPVC',
  //   contact_data: {
  //       title: <><h5>Secretaría de subastas</h5><h5>Poder Judicial del Chaco</h5></>,
  //       lines: [
  //           <p>
  //               <b>Dirección</b>
  //           </p>,
  //           <p>
  //               <b>Provincia</b>
  //           </p>,
  //           <p>
  //               <b>Teléfonos:</b>
  //           </p>,
  //           <p>&emsp; completar (directo)</p>,
  //           <p>&emsp; completar (interno xxx)</p>,
  //           <p>
  //               <b>email:</b> completar
  //           </p>,
  //       ],
  //   },
  //   support_email: 'soportechaco@esubasta.ar'
  // },
  //  Estas opciones para que muestre bienes de diversas fuentes
    //  cba: {
    //      url: 'https://subastas.justiciacordoba.gob.ar',
    //      logo: CbaLogo,
    //      logolight: CbaLogoLight,
    //      ws_path: 'wss://subastas.justiciacordoba.gob.ar/wss',
    //      name: 'Justicia Córdoba',
    //      navbar_class: 'navbar-cba',
    //      headroom_class: 'headroom-cba',
    //      favicon: CbaFav,
    //      theme_color: '#00857D',
    //      theme_name: 'cordoba',
    //  },
    //  sl: {
    //      url: 'https://subastas.justiciasanluis.gov.ar',
    //      logo: SLLogo,
    //      logolight: SLLogoLight,
    //      ws_path: 'wss://subastas.justiciasanluis.gov.ar/wss',
    //      name: 'Justicia San Luis',
    //      navbar_class: 'navbar-sl',
    //      headroom_class: 'headroom-sl',
    //      favicon: SLFav,
    //      theme_color: '#A80019',
    //      theme_name: 'sanluis',
    //  },
    //  er: {
    //      url:'https://subastas.jusentrerios.gob.ar',
    //      logo: ERLogo,
    //      logolight: ERLogoLight,
    //      ws_path: 'wss://subastas.jusentrerios.gob.ar/wss',
    //      name: 'Justicia Entre Ríos',
    //      navbar_class: 'navbar-er',
    //      headroom_class: 'headroom-er',
    //      favicon: ERFav,
    //      theme_color: '#676978',
    //      theme_name: 'entrerios',
    //  },
    //  lp:{
    //      url:'https://subastas.justicialapampa.gob.ar',
    //      logo: LPLogo,
    //      logolight: LPLogoLight,
    //      ws_path: 'wss://subastas.justicialapampa.gob.ar/wss',
    //      name: 'Justicia La Pampa',
    //      navbar_class: 'navbar-lp',
    //      headroom_class: 'headroom-lp',
    //      favicon: LPFav,
    //      theme_color: '#006699',
    //      theme_name: 'lapampa',
    //  },
};
